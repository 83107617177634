<template>
	<div class="d-flex flex-row justify-space-between pa-2" style="width: 100%">
		<div>
			<v-btn
				small
				dense
				@click="imageEditorDialog = true"
				color="primary"
				outlined
				block
				>{{ btnText || "Upload New Image" }}</v-btn
			>
		</div>

		<template v-if="editedImage && editedImage.imageSrc">
			<div style="width:100%; max-width: 250px;">
				<v-img
					contain
					style="border:solid 1px grey;"
					:aspect-ratio="imageWidth / imageHeight"
					:src="editedImage.imageSrc"
				></v-img>
			</div>
		</template>
		<template v-else-if="previewImageSrc">
			<div style="width:100%; max-width: 250px;">
				<v-img
					contain
					style="border:solid 1px grey;"
					:aspect-ratio="imageWidth / imageHeight"
					:src="previewImageSrc"
				></v-img>
			</div>
		</template>

		<template v-if="editedImage && editedImage.imageSrc">
			<div style="max-width: 50px">
				<v-btn color="error" icon @click="removeImage()">
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</div>
		</template>

		<ImageEditor
			@close="imageEditorDialog = false"
			@done="imageEditDone"
			:imageEditorDialogSize="imageEditorDialogSize"
			:imageEditorDialog="imageEditorDialog"
			:imageHeight="imageHeight"
			:imageWidth="imageWidth"
			:imageFormat="'png'"
		></ImageEditor>
	</div>
</template>

<script>
	import ImageEditor from "@/components/general/ImageEditor";
	export default {
		components: { ImageEditor },
		props: {
			btnText: {
				required: false
			},
			imageHeight: {
				required: true
			},
			imageWidth: {
				required: true
			},
			imageEditorDialogSize: {
				required: false,
				default: () => {
					return "720px";
				}
			},
			previewImageSrc: {
				required: false,
				default: null
			}
		},
		data() {
			return {
				editedImage: null,
				imageEditorDialog: false
			};
		},
		watch: {},
		methods: {
			imageEditDone(image) {
				this.editedImage = image;
				this.$emit("done", image);
			},
			removeImage() {
				this.editedImage = null;
				this.$emit("done", null);
			}
		}
	};
</script>

<style>
</style>
