<template>
	<v-container fluid>
		<v-card>
			<v-card-title> MGA Companies </v-card-title>
			<v-card-text>
				<v-row class="my-6">
					<v-col cols="4">
						<v-card
							class="fill-height transparent-1"
							@click="showCreateUpdateMgaCompanyDialog()"
						>
							<v-card-title>
								<v-spacer></v-spacer>
								Add MGA Company
								<v-spacer></v-spacer>
							</v-card-title>
							<v-card-text class="text-center">
								<v-icon color="primary" x-large>
									mdi-plus-circle-outline
								</v-icon>
							</v-card-text>
						</v-card>
					</v-col>
					<template v-for="(mgaCompany, index) in mgaCompanies">
						<v-col cols="4" :key="index">
							<v-card
								class="fill-height transparent-1"
								@click="showCreateUpdateMgaCompanyDialog(mgaCompany)"
							>
								<v-card-title>
									<v-spacer></v-spacer>
									{{ mgaCompany.name }}
									<v-spacer></v-spacer>
									<!-- <v-btn
										icon
										color="error"
										@click.stop="deleteMgaCompany(mgaCompany)"
									>
										<v-icon>mdi-delete</v-icon>
									</v-btn> -->
								</v-card-title>
								<v-card-text>
									Active: {{ mgaCompany.is_active ? "Yes" : "No" }}
								</v-card-text>
								<v-card-text>
									<v-row>
										<v-col cols="12" v-if="mgaCompany.company_logo_image_url">
											<div>
												Company Logo:
											</div>
											<v-img
												contain
												:src="mgaCompany.company_logo_image_url"
											></v-img>
										</v-col>
										<v-col
											cols="12"
											v-if="mgaCompany.agent_portal_logo_image_url"
										>
											<div>
												Agent Portal Logo:
											</div>
											<v-img
												contain
												:src="mgaCompany.agent_portal_logo_image_url"
											></v-img>
										</v-col>
									</v-row>
								</v-card-text>
							</v-card>
						</v-col>
					</template>
				</v-row>
			</v-card-text>

			<template v-if="createUpdateMgaCompanyDialog">
				<createUpdateMgaCompanyDialog
					:dialogModel="createUpdateMgaCompanyDialog"
					:selectedModel="selectedMgaCompany"
					@closeDialog="closeCreateUpdateMgaCompanyDialog()"
					@created="closeCreateUpdateMgaCompanyDialog(true)"
					@refreshList="getMgaCompanies()"
				/>
			</template>
		</v-card>
	</v-container>
</template>

<script>
	import createUpdateMgaCompanyDialog from "@/components/dialogs/createUpdateMgaCompanyDialog";
	export default {
		components: {
			createUpdateMgaCompanyDialog
		},
		head: {
			title: function() {
				return {
					inner: "MGA Management"
				};
			}
		},
		data() {
			return {
				mgaCompanies: [],
				selectedMgaCompany: null,
				createUpdateMgaCompanyDialog: false
			};
		},
		mounted() {
			this.getMgaCompanies();
		},
		methods: {
			showCreateUpdateMgaCompanyDialog(selectedMgaCompany = null) {
				if (selectedMgaCompany)
					this.selectedMgaCompany = Object.assign({}, selectedMgaCompany);
				this.createUpdateMgaCompanyDialog = true;
			},
			closeCreateUpdateMgaCompanyDialog() {
				this.selectedMgaCompany = null;
				this.createUpdateMgaCompanyDialog = false;
			},
			async getMgaCompanies() {
				let data = {};
				return await this.post(
					this.baseUrl + `/get-mga-companies`,
					data,
					true
				).then(data => {
					if (data == "undefined") return;
					if (data.code == 200) {
						this.mgaCompanies = data?.data?.mga_companies;
					}
				});
			},
			async deleteMgaCompany(mgaCompany) {
				if (
					!confirm(
						"By deleting this all the data related or sub related data to this company will be deleted?"
					)
				)
					return;

				if (!confirm("Are you sure you want to delete this company?")) return;

				let data = {
					mga_company_id: mgaCompany?.id
				};
				return await this.post(
					this.baseUrl + `/delete-mga-company`,
					data,
					true
				).then(data => {
					if (data == "undefined") return;
					if (data.code == 200) {
						this.getMgaCompanies();
					}
				});
			}
		}
	};
</script>
